<template>
  <div
    ref="formComponent"
    :class="[
      'container-' + organism.containerWidth,
      'organism form-cta bg-fr-beige',
    ]"
  >
    <div
      class="content max-w-[1220px] mx-auto flex flex-wrap xl:flex-nowrap gap-10 justify-center items-start pt-20 pb-10 px-8"
    >
      <img v-if="organism.image"
        class="rounded-full object-cover object-[60%_50%] w-[240px] aspect-square bg-gradient-to-b to-[#CCB59F] from-[#E1DBD4] border border-gray-400"
        :src="getImageSrc(organism.image, 'medium')"
        loading="lazy"
      />
      <div class="text max-w-[min(400px,_100%)]">
        <h2
          class="mb-4 font-cg font-semibold text-3xl md:text-[46px] leading-none text-gray-800"
        >
          {{ organism.title1 }}
        </h2>
        <div
          v-if="organism.description"
          class="text-lg text-gray-500 description font-figtree"
          v-html="organism.description"
        ></div>
      </div>
      <div class="form w-full max-w-[522px]">
        <header
          v-if="organism.formHeader"
          v-html="organism.formHeader"
          class="mb-4"
        ></header>
        <FormsHubSpotForm :form="form" @submit="handleSubmit"/>
      </div>
    </div>
  </div>
</template>

<style>
.form-cta {
  .text {
    h2 {
      @apply max-w-full;
    }

    h3 {
      @apply font-figtree text-2xl text-gray-800 font-semibold;
    }

    p {
      @apply mb-4 leading-tight;
    }

    a {
      @apply text-bc-dark-blue;
    }

    ul {
      @apply pl-8 pb-5;
    }

    .icon {
      @apply relative;

      &::before {
        @apply absolute w-5 h-5 -left-8 top-[1px];
      }

      &.icon-phone::before {
        content: url("/img/heroicon-phone.svg");
      }

      &.icon-home::before {
        content: url("/img/heroicon-home.svg");
      }

      &.icon-link::before {
        content: url("/img/heroicon-link.svg");
      }
    }
  }

  form {
    input[type="text"] {
      @apply border border-gray-400 col-span-2 md:col-span-1;

      &::placeholder {
        @apply text-sm;
      }
    }
    select {
      @apply border border-gray-400 col-span-2 text-sm text-gray-500;
    }
    textarea {
      @apply border border-gray-400;

      &::placeholder {
        @apply text-sm;
      }
    }
  }
}
</style>

<script setup>
const props = defineProps({
  organism: Object,
});

const formComponent = ref(null);

const form = computed(() => {
  return {
    id: props.organism?.hubspotFormId,
    hiddenFields: props.organism?.hiddenFormFields ?? [],
  };
});

const handleSubmit = () => {
  const offset = -200; // Adjust this value based on the height of your sticky nav
  const top = formComponent.value.getBoundingClientRect().top + window.scrollY + offset;
  window.scrollTo({ top, behavior: 'smooth' });
  // Add your form submission logic here
};

onMounted(() => {
  if (props.organism.containerWidth === "full-width") {
    addFullWidthSupport();
  }
});
</script>
